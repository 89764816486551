import styled from "styled-components"

export const Hero = styled.div`
  background: rgba(0, 0, 0, 0.15);
  font-size: 1.25rem;
  padding: 6rem 1rem;
  text-align: center;
  h1 {
    @media (min-width: 1024px) {
      font-size: 4rem;
    }
  }
`

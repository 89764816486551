import React from "react"

export type LogoIconProps = {
  height?: number
  width?: number
}

export const LogoIcon = ({ width = 48, height = 48 }: LogoIconProps) => {
  return (
    <svg
      version="1.1"
      x="0"
      y="0"
      viewBox="0 0 22 22"
      width={`${width}`}
      height={`${height}`}
    >
      <path
        fill="#e66a3a"
        d="M17.44 11.44h2.33v-1.07h-2.33c-1.9 0-1.94-.3-1.94-.3h-2.49l1.76-1.78s-.18-.24 1.17-1.6l1.65-1.67-.75-.75-1.65 1.67c-1.34 1.36-1.58 1.18-1.58 1.18L11.85 8.9V6.4s-.29-.04-.29-1.96V2.07H10.5V4.43c0 1.92-.29 1.96-.29 1.96V8.9L8.45 7.13s-.24.18-1.58-1.18L5.22 4.28l-.75.76 1.65 1.67c1.34 1.36 1.17 1.6 1.17 1.6l1.76 1.78H6.56s-.04.3-1.94.3H2.28v1.07H4.62c1.9 0 1.94.3 1.94.3h2.49l-1.76 1.78s.18.24-1.17 1.6l-.12.1-1.53 1.54.75.75 1.53-1.55.11-.12.01-.01c1.34-1.36 1.58-1.18 1.58-1.18l1.76-1.78v2.51s.29.04.29 1.96v2.36h1.05v-2.36c0-1.92.29-1.96.29-1.96v-2.51l1.76 1.78s.24-.18 1.58 1.18l.01.01.11.12 1.53 1.55.75-.75-1.53-1.54-.12-.13c-1.34-1.36-1.17-1.6-1.17-1.6L13 11.73h2.49c0 .01.05-.29 1.95-.29m-6.41 1.34c-1.02 0-1.85-.84-1.85-1.87s.83-1.87 1.85-1.87 1.85.84 1.85 1.87-.83 1.87-1.85 1.87"
      />
      <path
        fill="#e66a3a"
        d="M11.03 9.28c-.89 0-1.62.73-1.62 1.63s.72 1.63 1.62 1.63c.89 0 1.62-.73 1.62-1.63s-.73-1.63-1.62-1.63m0 2.28a.64.64 0 01-.64-.65c0-.36.29-.65.64-.65.36 0 .64.29.64.65 0 .36-.29.65-.64.65"
      />
      <path
        fill="#e66a3a"
        d="M11.03 1.78c1.22 0 2.4.24 3.52.72 1.08.46 2.04 1.12 2.87 1.96s1.48 1.82 1.94 2.9c.47 1.13.71 2.32.71 3.56a9.11 9.11 0 01-2.65 6.45c-.83.84-1.8 1.5-2.87 1.96-1.11.48-2.3.72-3.52.72-1.22 0-2.4-.24-3.52-.72a8.938 8.938 0 01-2.87-1.96 9.285 9.285 0 01-1.94-2.9 9.282 9.282 0 010-7.12 9.171 9.171 0 014.81-4.86c1.11-.47 2.3-.71 3.52-.71m0-1.43C5.26.35.59 5.08.59 10.91s4.67 10.56 10.44 10.56 10.44-4.73 10.44-10.56S16.79.35 11.03.35"
      />
    </svg>
  )
}

import { createGlobalStyle } from "styled-components"

export const GlobalStyles = createGlobalStyle`
html,
    body,
    button,
    div,
    span,
    object,
    iframe,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    blockquote,
    pre,
    abbr,
    address,
    cite,
    code,
    del,
    dfn,
    em,
    ins,
    kbd,
    q,
    samp,
    small,
    strong,
    sub,
    sup,
    var,
    b,
    i,
    dl,
    dt,
    dd,
    ol,
    ul,
    li,
    fieldset,
    form,
    label,
    legend,
    table,
    caption,
    tbody,
    tfoot,
    thead,
    tr,
    th,
    td,
    article,
    aside,
    figure,
    footer,
    header,
    hgroup,
    menu,
    nav,
    section,
    time,
    mark,
    audio,
    video {
      background: transparent;
      border: 0;
      font-size: 100%;
      margin: 0;
      outline: 0;
      padding: 0;
      vertical-align: baseline;
    }

    article,
    aside,
    figure,
    footer,
    header,
    main,
    nav,
    section {
      display: block;
    }

    *,
    *:before,
    *:after {
      box-sizing: border-box;
    }

    :root {
      font-size: 16px;
    }

    html {
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
        Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
        'Segoe UI Symbol';
    }

    html,
    body {
      width: 100%;
      height: 100%;
      background: #212b2f;
      color: white;
    }

    a {
      color: white;
      text-decoration: underline;
      opacity: 0.8;
      &:hover,
      &:focus {
        opacity: 1;
      }
    }

    h1,
    .h1,
    h2,
    .h2,
    h3,
    .h3,
    h4,
    .h4,
    h5,
    .h5,
    h6,
    .h6 {
      font-weight: 600;
    }

    h1,
    .h1 {
      font-size: 3rem;
      line-height: 1.25;
      margin-bottom: 1rem;
    }

    h2,
    .h2 {
      font-size: 2.25rem;
      line-height: 1.33333333;
      margin-bottom: 0.75rem;
    }

    h3,
    .h3 {
      font-size: 1.5rem;
      line-height: 1.16666667;
      margin-bottom: 0.75rem;
    }

    h4,
    .h4 {
      font-size: 1.25rem;
      line-height: 1.25;
      margin-bottom: 0.75rem;
    }

    h5,
    .h5 {
      font-size: 0.75rem;
      font-weight: 800;
      line-height: 1.33333333;
      margin-bottom: 0.25rm;
      text-transform: uppercase;
    }

    h6,
    .h6 {
      font-weight: 700;
    }

    p {
      margin-bottom: 0.75rem;
    }

    small {
      font-size: 0.75rem;
    }

    ul,
    ol {
      padding-left: 1.25rem;
    }

    nav {
      ul {
        display: flex;
        list-style: none;
        padding-left: 0;
      }
      a {
        display: inline-block;
        padding: 0.5rem;
      }
    }

    .center {
      margin: 0 auto;
    }
`

import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { LogoIcon } from "./LogoIcon"

export const GlobalNav = () => {
  return (
    <NavWrapper>
      <Nav>
        <Link to="/">
          <LogoIcon />
        </Link>
        <ul>
          <li>
            <a
              href="https://apps.apple.com/us/app/sober-wagon/id1545963475"
              title="Download from the App Store"
            >
              Download
            </a>
          </li>
        </ul>
      </Nav>
    </NavWrapper>
  )
}

const Nav = styled.nav`
  align-items: center;
  background-color: #1a2023;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0.5rem;

  ul {
    margin: 0.5rem 0;
  }
  li {
    margin-bottom: 0;
  }
`

const NavWrapper = styled.div`
  position: sticky;
  top: 0;
  z-index: 10;
`
